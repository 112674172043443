import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { Container, Row, Col } from 'react-grid-system'
import { useIntl } from 'gatsby-plugin-intl'

import Layout from '../../../../components/Layouts/layout'
import SEO from '../../../../components/seo'
import Alert from '../../../../components/Alert/Alert'
import LinkList from '../../../../components/LinkList/LinkList'

const ReserachEdPage = () => {
  const intl = useIntl()
  const data = useStaticQuery(graphql`
    query {
      eventImg2: file(
        relativePath: { eq: "resourceHub/EQ-Workshop-June2024-Li.jpg" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Layout
      breadcrumb={{
        text: 'Technical Research & Education: Resource Hub',
        path:
          '/about/research-and-education-program/technical-research-and-education-resource-hub/'
      }}
      header={<span className='bold'>Upcoming Educational Offerings</span>}
    >
      <SEO lang={intl.locale} title='Upcoming Educational Offerings' />
      <Container>
        {intl.locale === 'fr' && (
          <Row align='stretch'>
            <Col>
              <Alert header='Attention'>
                <p lang='fr'>
                  Pour les services en français, veuillez contacter le Service
                  des licences et à la clientèle de l’Office de réglementation
                  de la construction des logements au 416-487-HCRA (4272) ou{' '}
                  <a href='mailto:info@hcraontario.ca'>info@hcraontario.ca</a>
                </p>
              </Alert>
            </Col>
          </Row>
        )}
        <Row className='pageRow'>
          <Col>
            <h2>National Building Code 9.36 Energy Efficiency Education</h2>
            <LinkList
              links={[
                {
                  path:
                    'https://rsmtrainingcentre.thinkific.com/courses/energy-efficiency',
                  text: 'Free Online Course',
                  description:
                    'NBC 9.36: Energy Efficiency for Small Buildings, developed by ACBOA, NRCan and RSM Building Consultants'
                }
              ]}
            />
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default ReserachEdPage
